<template>
  <div>
    <h2 class="h4">Start a new discussion</h2>
    <div class="card">
      <div class="card-body">
        <form action="#" @submit.prevent="save">
          <div class="form-group">
            <label>Topic</label>
            <input
              type="text"
              placeholder="Topic"
              v-model="topic.title"
              required
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Category</label>
            <select v-model="topic.category_id" class="form-control" required>
              <option
                :value="category.id"
                v-for="(category, i) in categories"
                :key="`category-${i}`"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Content</label>
            <editor
              api-key="no-api-key"
              v-model="topic.content"
              :init="{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help',
              }"
            />
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Has Attachment?</label>
                <select class="form-control" v-model="topic.attachment">
                  <option value="none">None</option>
                  <option value="image">Image</option>
                  <option value="image-url">Image URL</option>
                  <option value="video">Video</option>
                </select>
              </div>
            </div>
            <div class="col-md-9">
              <div class="form-group" v-if="topic.attachment == 'video'">
                <label>Video Url</label>
                <input
                  type="text"
                  placeholder="Video URL"
                  v-model="topic.video_url"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group" v-if="topic.attachment == 'image'">
                <label>Image</label>
                <input
                  type="file"
                  placeholder="Select Image"
                  class="form-control"
                  ref="selected_image"
                  required
                />
              </div>
              <div class="form-group" v-if="topic.attachment == 'image-url'">
                <label>Image Url</label>
                <input
                  type="text"
                  placeholder="Image URL"
                  v-model="topic.image_url"
                  class="form-control"
                  required
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-secondary">Start Discussion</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      topic: {
        attachment: "none",
      },
      categories: [],
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/admin/discussions/create").then((response) => {
        this.categories = response.data.categories;
      });
    },

    save() {
      let data = new FormData();
      data.append("title", this.topic.title);
      data.append("category_id", this.topic.category_id);
      data.append("attachment", this.topic.attachment);
      data.append("video_url", this.topic.video_url);
      data.append("content", this.topic.content);

      if (this.topic.attachment == "image") {
        data.append("image", this.$refs.selected_image.files[0]);
      }

      if (this.topic.attachment == "image-url") {
        data.append("image_url", this.topic.image_url);
      }

      this.$loader.start();
      this.$axios
        .post("/api/v1/admin/discussions", data, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.$loader.stop();
          this.$router.push({
            name: "admin.discussions.show",
            params: { id: response.data.id },
          });
        });
    },
  },
};
</script>
